"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_virtuoso_1 = require("react-virtuoso");
const LoadingProductCard_1 = require("@components/pages/search/LoadingProductCard");
const makeStyles_1 = require("@material-ui/core/styles/makeStyles");
const createStyles_1 = require("@material-ui/core/styles/createStyles");
const LabelContext_1 = require("@components/common/context/LabelContext");
const ProductCard_1 = require("@components/pages/product/ProductCard");
const HBTypography_1 = require("@components/library/HBTypography");
const useStyles = makeStyles_1.default(({ spacing }) => createStyles_1.default({
    root: {
        padding: spacing(0),
        position: 'relative',
        minHeight: '100vh',
    },
    grid: {
        outline: 'none',
        marginTop: spacing(1),
        marginBottom: spacing(1),
        display: 'flex',
        flexWrap: 'wrap',
    },
    gridItem: {
        padding: spacing(1.5),
        width: '25%',
        display: 'flex',
        flex: 'none',
        alignContent: 'stretch',
        boxSizing: 'border-box',
        '@media (max-width: 1024px)': {
            width: '33%',
        },
        '@media (max-width: 768px)': {
            width: '50%',
        },
        '@media (max-width: 480px)': {
            width: '50%',
        },
    },
    footer: {
        padding: '1rem',
        textAlign: 'center',
    },
}));
const VirtualGrid = ({ products, loadMore, isLoading, endReached }) => {
    const classes = useStyles();
    const labels = LabelContext_1.useLabelContext();
    const List = React.forwardRef((props, ref) => (React.createElement("div", Object.assign({}, props, { className: classes.grid, ref: ref }))));
    const Item = props => React.createElement("div", Object.assign({}, props, { className: classes.gridItem }));
    const Footer = () => React.createElement("div", { className: classes.footer }, labels.END_OF_SEARCH_RESULTS);
    const VirtualComponents = React.useMemo(() => {
        return {
            List,
            Item,
        };
    }, []);
    return (React.createElement("div", { className: classes.root },
        React.createElement(react_virtuoso_1.VirtuosoGrid, { useWindowScroll: true, initialItemCount: products.length, totalCount: products.length, overscan: 100, endReached: loadMore, components: VirtualComponents, itemContent: index => React.createElement(ProductCard_1.default, Object.assign({ key: index }, products[index], { imgSize: 258 })) }),
        React.createElement("div", { className: classes.grid }, !!(products === null || products === void 0 ? void 0 : products.length) &&
            isLoading &&
            !endReached &&
            [...Array(10).keys()].map(index => (React.createElement("div", { key: index, className: classes.gridItem },
                React.createElement(LoadingProductCard_1.default, null))))),
        (!(products === null || products === void 0 ? void 0 : products.length) || endReached) && (React.createElement(HBTypography_1.default, { align: "center", variant: "h6", marginTop: 5, marginBottom: 5 }, labels.END_OF_SEARCH_RESULTS))));
};
exports.default = VirtualGrid;
