"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVirtualGridRendererContext = void 0;
const React = require("react");
const useLoadMoreResults_1 = require("@components/common/VirtualGridRenderer/useLoadMoreResults");
const ApiContext_1 = require("@components/common/context/ApiContext");
const VirtualGridRendererContext = React.createContext({});
const VirtualGridRendererContextProvider = ({ api, children, initData, fullSlug, }) => {
    const [currentPageNumber, setCurrentPageNumber] = React.useState(0);
    const { loadedResults, loadMoreProducts, endReached, isLoading } = useLoadMoreResults_1.useLoadMoreResults(api, initData);
    const loadMore = () => {
        loadMoreProducts({ slug: fullSlug, page: currentPageNumber + 1 }).then(() => setCurrentPageNumber(currentPageNumber + 1));
    };
    React.useEffect(loadMore, []);
    return (React.createElement(VirtualGridRendererContext.Provider, { value: {
            loadedResults,
            loadMore,
            endReached,
            isLoading,
        } }, children));
};
exports.default = ApiContext_1.withApi(VirtualGridRendererContextProvider);
exports.useVirtualGridRendererContext = () => React.useContext(VirtualGridRendererContext);
